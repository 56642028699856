import React from "react";

const Footer = () => {
  return (
    <footer className="main-footer text-center">
      <strong>
        Copyright © 2024 <a href="https://adminps.psh.com.bd/">PSH Admin</a>.
      </strong>
      <span> All rights reserved.</span>
    </footer>
  );
};

export default Footer;
