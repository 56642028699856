import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import NewOrders from "../components/Orders/NewOrders";
const AdminOrders = () => {
  return (
    <div>
      <NewOrders />
    </div>
  );
};

export default AdminOrders;
