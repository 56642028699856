/* eslint-disable react/jsx-pascal-case */
import React from "react";
import Event_list from "../components/Event/Event_list";

const Event = () => {
  return (
    <div>
      <Event_list />
    </div>
  );
};

export default Event;
