/* eslint-disable react/jsx-pascal-case */
import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Facility_list from "../components/Facility/Facility";
const Facility = () => {
  return (
    <div>
      <Facility_list />
    </div>
  );
};

export default Facility;
