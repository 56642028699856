/* eslint-disable react/jsx-pascal-case */
import React from "react";
import Recommended_list from "../components/Recommended/Recommended_list";

const Recommended = () => {
  return (
    <div>
      <Recommended_list />
    </div>
  );
};

export default Recommended;
