import React from "react";
import "./Home.css";

import NewOrders from "../Orders/NewOrders";

const Homes = () => {
  return (
    <div className="mt-0">
      <NewOrders />
    </div>
  );
};

export default Homes;
