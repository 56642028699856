/* eslint-disable react/jsx-pascal-case */
import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Dynamic_list from "../components/Dynamic/Dynamic_list";
const Dynamic = () => {
  return (
    <div>
      <Dynamic_list />
    </div>
  );
};

export default Dynamic;
