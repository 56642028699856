/* eslint-disable react/jsx-pascal-case */
import React from "react";
import Review_list from "../components/Review/Review";

const Review = () => {
  return (
    <div>
      <Review_list />
    </div>
  );
};

export default Review;
